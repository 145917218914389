import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

// Single Profile Page

const Team = ({ data, location }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;

  return (
    <Layout bodyClass="page-team-single">
      <SEO title={title} url={location.href} />
      <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12 col-md-12">
            <div className="service service-single">
              <h1 className="title">{title}</h1>
              <div className="d-flex">
                <div
                  className="content team-member"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                <div className="pl-2 flex-shrink-1">
                  <img
                    className="profile-foto"
                    src={data.markdownRemark.frontmatter.image}
                    alt={data.markdownRemark.frontmatter.title}
                  />
                </div>
              </div>
              <Link to="/team/">↞ Zurück zur Teamübersicht</Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image
      }
      fields {
        slug
      }
      html
      tableOfContents
    }
  }
`;

export default Team;
